.switch-options-row {
  display: flex;
  height: fit-content;
}

.switch-option {
  padding: 4px 12px;
  background-color: $softGray;
  align-content: center;
  border: none;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  span {
    color: $inkBlue;
  }
}

.switch-option.active {
  background-color: $inkBlue;

  span {
    color: $white;
  }
}