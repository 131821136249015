.sweet-alert {
  position: absolute !important;
  top: 100px !important;

  h2 {
    margin: 0 !important;
  }

  .text-container {
    display: grid;
    justify-items: center;
    gap: 4px;
  }
}
