/*!

 =========================================================
 * Paper Dashboard PRO React - v1.3.0 based on Paper Dashboard 2 Pro - v2.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import 'paper-dashboard/variables';
@import 'paper-dashboard/mixins';

// Core CSS
@import 'paper-dashboard/animated-buttons';
@import 'paper-dashboard/badges';
@import 'paper-dashboard/buttons';
@import 'paper-dashboard/checkboxes-radio';
@import 'paper-dashboard/dropdown';
@import 'paper-dashboard/example-pages';
@import 'paper-dashboard/fonts';
@import 'paper-dashboard/footers';
@import 'paper-dashboard/icons';
@import 'paper-dashboard/info-areas';
@import 'paper-dashboard/inputs';
@import 'paper-dashboard/misc';
@import 'paper-dashboard/misc-extend';
@import 'paper-dashboard/navbar';
@import 'paper-dashboard/pagination';
@import 'paper-dashboard/responsive';
@import 'paper-dashboard/sections';
@import 'paper-dashboard/sidebar-and-main-panel';
@import 'paper-dashboard/sweet-alert';
@import 'paper-dashboard/switch';
@import 'paper-dashboard/tables';
@import 'paper-dashboard/typography';

// cards
@import 'paper-dashboard/cards';
@import "paper-dashboard/cards/card-stats";

// react differences
@import "paper-dashboard/react/react-differences";
