%card-stats {
  hr {
    margin: 5px 15px;
  }
}

.card-row {
  gap: 8px;
  display: flex;
  padding-bottom: 8px;
}

.card-stats {
  .card-body {
    padding: 15px 15px 0px;
    
    .oath-body {
      &.gray {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .card-footer {
    padding: 0px 15px 15px;

    .stats {
      color: $dark-gray;
    }

    hr {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
  .icon-big {
    font-size: 3em;
    min-height: 64px;

    i {
      line-height: 59px;
    }
  }
}

.card-title {
  margin-bottom: 4px;
  @extend .oath-bodysmall;
  color: $inkBlue;
}