@font-face {
  font-family: 'DMSans-Light';
  font-style: normal;
  src: url('~assets/fonts/DMSans-Light.ttf') format('truetype');
  font-weight: 300;
}

  @font-face {
  font-family: 'DMSans-Regular';
  font-style: normal;
  src: url('~assets/fonts/DMSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

  @font-face {
  font-family: 'DMSans-SemiBold';
  font-style: normal;
  src: url('~assets/fonts/DMSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
