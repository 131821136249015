.chevron-svg {
  background-color: $gray;
  border-radius: 20px;
  padding: 3px;
}

.chevron-svg-inkblue {
  @extend .chevron-svg;
  background-color: $inkBlue;
}

.chevron-svg-light {
  @extend .chevron-svg;
  background-color: $white;
  border: 1px solid $inkBlue;
}

.oath-user-svg {
  background-color: $oathBlue;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  padding: 6px;
  margin-right: 8px;
}
