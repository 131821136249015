// Based on the original react-tagsinput styles

.react-tagsinput {
  display: inline-block;
  padding: 4px 6px;
  max-width: 100%;
  line-height: 22px;
}

.react-tagsinput-tag {
  transition: all 0.17s linear;
  cursor: pointer;
  margin: 5px 3px 5px 0;
  position: relative;
  padding: 4px 8px 3px 0.8em;
  border-radius: 12px;
  color: #fff;
  font-size: 0.75em;
  font-weight: 500;
  display: inline-block;
  line-height: 1.5em;
  text-transform: uppercase;
  border: 1px solid;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: '\f00d';
  font-family: 'FontAwesome';
  padding: 0px 2px;
}
.react-tagsinput-tag a {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0px;
  opacity: 0;
  background-color: transparent;
}

.input-color {
  width: 170px;
  height: 40px;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-top: -5px;
  margin-left: -1px;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: auto;
  // width: 80px;
}

.react-tagsinput {
  .react-tagsinput-tag {
    -webkit-transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
    transition: all 300ms ease 0s;

    &:hover {
      padding-right: 22px;

      a {
        opacity: 1;
        padding-right: 4px;
      }
    }

    @include badge-color($default-color);
    &.primary {
      @include badge-color($primary-color);
    }
    &.info {
      @include badge-color($info-color);
    }
    &.success {
      @include badge-color($success-color);
    }
    &.warning {
      @include badge-color($warning-color);
    }
    &.danger {
      @include badge-color($danger-color);
    }
    &.neutral {
      @include badge-color($white-color);
      color: inherit;
    }
  }
}
